import React, { useState, useEffect } from 'react';

import LayoutPortal from '../../components/layout-portal';
import CustomFetch from '../../components/fetch';
import Seo from '../../components/seo';
import CustomAlert from '../../components/alert';
import { toast } from 'react-toastify';

export default function ProfilePage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [alertState, setAlertState] = useState({
        alertClass: 'dialog success',
        alertId: 'dialogWrap',
        alertText: '',
        show: false,
    });

    const alertStateChange = (data) => {
        setAlertState({
            show: data.show,
            alertText: data.alertText,
            alertClass: data.alertClass,
        });
    };

    const handleChange = (e) => {
        if (e) {
            switch (e.target.name) {
                case 'email':
                    setEmail(e.target.value);
                    break;
                case 'password':
                    setPassword(e.target.value);
                    break;
                case 'passwordConfirm':
                    setPasswordConfirm(e.target.value);
                    break;
            }
        }
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();

            let _error = '';

            if (password === '') {
                _error += 'Please enter a new password.\r\n';
            } else {
                if (password !== passwordConfirm) {
                    _error +=
                        'Please make sure the password and confirm password field has the same value.\r\n';
                }
            }

            if (_error === '') {
                let userProfile = JSON.parse(
                    localStorage.getItem('ofpUserProfile')
                );

                CustomFetch(
                    `${process.env.GATSBY_API_URL}/users/${userProfile.id}`,
                    'PUT',
                    {
                        password: password,
                    },
                    function (result) {
                        toast.success('Password updated successfully.');
                    },
                    function (error) {
                        toast.error(String(error));
                    }
                );
            } else {
                toast.error(String(_error));
            }
        }
    };

    useEffect(() => {
        let userProfile = localStorage.getItem('ofpUserProfile');

        if (userProfile && userProfile !== '') {
            userProfile = JSON.parse(userProfile);

            setName(userProfile.firstname + ' ' + userProfile.surname);
            setEmail(userProfile.email.replace('@virtualportal.com.au', ''));
        }
    }, []);

    return (
        <LayoutPortal>
            <Seo title="Profile" description="Client Portal - Profile" />
            <div className="container container--portal">
                <div className="grid">
                    <div className="col-lg-12">
                        <div className="pitem">
                            <div className="plist">
                                <div className="plist__header">Profile</div>
                                <div className="plist__form">
                                    <p>
                                        <strong>{name}</strong> Change Password
                                    </p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="pformitem">
                                            <label htmlFor="email">
                                                Username
                                            </label>
                                            <input
                                                type="text"
                                                autoComplete="off"
                                                name="email"
                                                value={email}
                                                onChange={handleChange}
                                                readOnly
                                            ></input>
                                        </div>
                                        <div className="pformitem">
                                            <label htmlFor="password">
                                                New Password
                                            </label>
                                            <input
                                                type="password"
                                                name="password"
                                                value={password}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                        <div className="pformitem">
                                            <label htmlFor="confirm">
                                                Confirm New Password
                                            </label>
                                            <input
                                                type="password"
                                                name="passwordConfirm"
                                                value={passwordConfirm}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                        <div className="pformbtn">
                                            <button type="submit">
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomAlert alertState={alertState} />
        </LayoutPortal>
    );
}
